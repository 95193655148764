import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { getImage, GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import { infogrid, overview, closerLineHeight, threeGrid, threeGrid2, elem2, infos, info, image, orange, subTitle } from "../src/pages/works/WorkPage.module.css";
import depthCam from "../src/images/snapshot/depthCam.png";
import pointCloud from "../src/images/snapshot/pointCloud.gif";
import depthInfo from "../src/images/snapshot/depthInfo.gif";
import buckets from "../src/images/snapshot/bucket.gif";
import allViews from "../src/images/snapshot/demo_allview.gif";
import final from "../src/images/snapshot/snapshot.gif";
import exhibition1 from "../src/images/snapshot/display.jpg";
import exhibition2 from "../src/images/snapshot/print.jpg";
import exhibition3 from "../src/images/snapshot/morePrints.jpg";
import exhibition4 from "../src/images/snapshot/printing.gif";
export const _frontmatter = {
  "title": "Snapshot",
  "description": "Snapshot is a Kinect-based project that displays a composite image of what was captured over time through depth.",
  "title_color": "#565252",
  "order": 11,
  "hero_img": "../src/images/snapshot/snapshot_hero.png",
  "hero_img_alt": "One output of snapshot taken in a bedroom",
  "hero_img_description": "Snapshot Example",
  "next": "Thalassia",
  "next_link": "thalassia",
  "next_color": "#DBFFF9",
  "next_img": "../src/images/thalassia/main.jpg",
  "prev": "Cre·​ate",
  "prev_link": "create",
  "prev_color": "#565252",
  "prev_img": "../src/images/create/main.jpeg"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <div className={infogrid}>
      <div className={overview}>
        <div>
          <p><strong parentName="p">{`Overview`}</strong></p>
        </div>
        <div className={closerLineHeight}>
Snapshot is a Kinect-based project that displays a composite image of what was captured over time through depth. Each layer that builds up the image contains the outline of objects captured at different depth range at different time. The layers are organized by time: The oldest capture is at the background and the newest capture is at the foreground.
        </div>
      </div>
      <div className={infos}>
        <div className={info}>
          <div>
            <p><strong parentName="p">{`Duration `}</strong></p>
          </div>
          <div className={closerLineHeight}> 
Nov 22 - Dec 6,2022 <br /> (2 weeks)
          </div>
        </div>
        <div className={info}>
          <div>
            <p><strong parentName="p">{`Tool `}</strong></p>
          </div>
          <div className={closerLineHeight}> 
openFrameworks <br /> Kinect <br /> Thermal Printer
          </div>
        </div>
      </div>
    </div>
    <div className={subTitle}>
Inspiration
    </div>
    <div style={{
      "marginBottom": "3rem"
    }}>
      <p>{`Extending the from `}<a parentName="p" {...{
          "href": "https://github.com/kitetale/Travel-Over-Time"
        }}>{` `}<i>{`Travel Over Time `}</i>{` `}</a>{`
project, I wanted to further find a way to capture a location over time.
Depending on the time of the day, the same location may be empty or extremely crowded.
Sometimes an unexpected passes by the location, but it would be only seen at that specific time.
Wondering what the output would look like if each depth layer is the capture of the same location from different point of time,
I developed a program that separately saves the kinect input image by depth range and compiles randomly selected images per layer
from the past in a chronological order.`}</p>
    </div>
    <div className={subTitle}>
Process
    </div>
    <div className={threeGrid} style={{
      "paddingBottom": "0.5rem"
    }}>
      <div style={{
        "margin": "auto",
        "width": "80%",
        "paddingBottom": "0.5rem"
      }}>
I first used Kinect to get the depth data of the scene
      </div>
      <img style={{
        "width": "100%"
      }} src={depthCam} alt={"depth data image from Kinect camera"} />
    </div>
    <div className={threeGrid2} style={{
      "paddingBottom": "0.5rem"
    }}>
      <img style={{
        "width": "100%"
      }} src={pointCloud} alt={"point cloud created from depth data obtained from Kinect camera"} />
      <div style={{
        "margin": "auto",
        "width": "80%",
        "paddingBottom": "0.5rem"
      }}> Then I created point cloud with the depth information as z </div>
    </div>
    <div className={threeGrid} style={{
      "paddingBottom": "0.5rem"
    }}>
      <div style={{
        "margin": "auto",
        "width": "80%",
        "paddingBottom": "0.5rem"
      }}>
I then divided the points into 6 buckets based on their depth range with HSB color value, which is also based on depth
      </div>
      <img style={{
        "width": "100%"
      }} src={depthInfo} alt={"point cloud points divided into 6 buckets, sorted by color"} />
    </div>
    <div className={threeGrid2} style={{
      "paddingBottom": "0.5rem"
    }}>
      <img style={{
        "width": "100%"
      }} src={buckets} alt={"point cloud points above put into triangular mesh by connecting adjacent three points"} />
      <div style={{
        "margin": "auto",
        "width": "80%",
        "paddingBottom": "0.5rem"
      }}> I also created triangular mesh out of points per bucket </div>
    </div>
    <div className={threeGrid} style={{
      "paddingBottom": "0.5rem"
    }}>
      <div style={{
        "margin": "auto",
        "width": "80%",
        "paddingBottom": "0.5rem"
      }}>
I wrote a function that would automatically save the data per layer every certain time interval 
(e.g. every minute, every 10 seconds, etc.)
        <p>{`These are different views of Kinect:`}</p>
        <p>{`(Starting with top left and clock-wise: depth data, RGB data, object outlines, compiled depth buckets in grayscale, individual bucket in grayscale)`}</p>
      </div>
      <img style={{
        "width": "100%"
      }} src={allViews} alt={"different views and data obtained from Kinect camera. Starting with top left and clock-wise: depth data, RGB data (normal camera), object outlines, compiled depth buckets in grayscale, individual bucket in grayscale."} />
    </div>
    <div className={subTitle} style={{
      "marginTop": "3rem"
    }}>
How It works
    </div>
    <div style={{
      "marginBottom": "3rem"
    }}>
Upon pressing a button to capture, the program generates 6 random numbers and sorts them in order to pull captured layers in chronological order. It then combines the layers all together by taking the biggest pixel value across 6 layer images. Once the image is constructed, it frames in a polaroid template with location and timeframe (also saved and retrieved in the same manner as the layers) below the image.
    </div>
    <div style={{
      "margin": "auto",
      "justifyContent": "center",
      "display": "flex"
    }}>
      <img className={image} src={final} alt={"Example snapshot outputs."} />
    </div>
    <div className={subTitle} style={{
      "marginTop": "3rem"
    }}>
Exhibition Installation
    </div>
    <div style={{
      "margin": "auto",
      "display": "flex",
      "flexDirection": "row",
      "flexWrap": "wrap",
      "justifyContent": "center"
    }}>
      <img style={{
        "width": "40%",
        "boxShadow": "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
        "marginRight": "1rem",
        "marginBottom": "1rem"
      }} src={exhibition1} alt={"Exhibition of Snapshot at The STUDIO for Creative Inquiry."} />
      <img style={{
        "width": "40%",
        "boxShadow": "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
        "marginRight": "1rem",
        "marginBottom": "1rem"
      }} src={exhibition4} alt={"Exhibition of Snapshot at The STUDIO for Creative Inquiry."} />
      <img style={{
        "width": "40%",
        "boxShadow": "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
        "marginRight": "1rem",
        "marginBottom": "1rem"
      }} src={exhibition2} alt={"Exhibition of Snapshot at The STUDIO for Creative Inquiry."} />
      <img style={{
        "width": "40%",
        "boxShadow": "0 10px 16px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%)",
        "marginRight": "1rem",
        "marginBottom": "1rem"
      }} src={exhibition3} alt={"Exhibition of Snapshot at The STUDIO for Creative Inquiry."} />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      